import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';

import validators from 'utils/validators';
import FormCard from 'components/FormCard';
import AnimateButton from 'components/@extended/AnimateButton';
import Button from 'components/Button';
import React, { useCallback, useEffect, useState } from 'react';
import DadosDoParceiro from './DadosDoParceiro';
import Endereco from './Endereco';
import * as actions from 'reducers/parceiros.reducer';
import Loader from 'components/Loader';
import MainCard from 'components/MainCard';
import { Box, Typography } from '@mui/material';
import MainLayout from 'layout/MainLayout/index';

import {
  actions as routeActions,
  types as routes
} from 'reducers/rotas.actions';
import { removeNonDigitsFromString } from 'utils/basic';
import DadosDoProprietario from './DadosDoProprietario';
import DadosDoFaturamento from './DadosDoFaturamento';
import { validateCpfCnpj } from 'utils/validateCpfCnpj ';
import DadosPalletaCores from './DadosPaletaCores';
import DadosWhitelabel from './DadosWhitelabel';

const Parceiro = () => {
  const dispatch = useDispatch();
  const parceiro = useSelector((state) => state.parceiros.parceiro.data);
  const loading = useSelector((state) => state.parceiros.parceiro.loading);
  const arquivos = useSelector((state) => state.parceiros.arquivos.data);

  const origensParceiro = [
    { codigo: 'RM', nome: 'RE/MAX' },
    { codigo: 'IN', nome: 'Indicação' },
    { codigo: 'PR', nome: 'Prospecção' },
    { codigo: 'ST ', nome: 'Site' },
    { codigo: 'MK', nome: 'Marketing' }
  ];

  const perfis = [
    { codigo: 'AS', nome: 'Assessoria' },
    { codigo: 'IM', nome: 'Imobiliária' },
    { codigo: 'CO', nome: 'Corretor' },
    { codigo: 'CA', nome: 'Cartório' }
  ];

  const [files, setFile] = useState({
    fileLogoLogin: '',
    fileLogoHeader: ''
  });
  const [open, setOpen] = useState({
    isOpen: false,
    file: ""
  });

  const isEditMode = useSelector(
    (state) => state.parceiros.parceiro.isEditMode
  );

  const regionais = useSelector((state) => state.dominios.regional.data) ?? [];

  const ufs = useSelector((state) => state.dominios.unidadesFederativa);

  const disabled = !isEditMode ? false : parceiro?.tipoSituacao !== 'A';

  const initialValues = {
    // Dados do Parceiro
    nomeParceiro: parceiro?.nomeParceiro ?? '',
    cpfCnpj: parceiro?.cpfCnpj ?? null,
    tipoOrigem: parceiro?.tipoOrigem ?? '',
    nomeProprietario: parceiro?.nomeProprietario ?? '',
    emailProprietario: parceiro?.emailProprietario ?? '',
    celularProprietario: parceiro?.celularProprietario ?? '',
    nomeFaturamento: parceiro?.nomeFaturamento ?? '',
    emailFaturamento: parceiro?.emailFaturamento ?? '',
    celularFaturamento: parceiro?.celularFaturamento ?? '',
    cep: parceiro?.cep ?? '',
    logradouro: parceiro?.logradouro ?? '',
    numeroLogradouro: parceiro?.numeroLogradouro ?? '',
    complementoLogradouro: parceiro?.complementoLogradouro ?? '',
    bairro: parceiro?.bairro ?? '',
    municipio: parceiro?.municipio ?? '',
    uf: parceiro?.uf ?? '',
    idUsuarioCriacao: parceiro?.idUsuarioCriacao ?? null,
    dataHoraCriacao: parceiro?.dataHoraCriacao ?? '',
    idUsuarioAlteracao: parceiro?.idUsuarioAlteracao ?? null,
    dataHoraAlteracao: parceiro?.dataHoraAlteracao ?? '',
    idRegional: parceiro?.idRegional ?? null,
    tipoSituacao: parceiro?.tipoSituacao ?? '',
    origem: origensParceiro.find((el) => el.codigo === parceiro?.tipoOrigem),
    regional:
      regionais?.find((el) => el.idRegional === parceiro?.idRegional) ?? null,
    perfil: perfis.find((el) => el.codigo === parceiro?.perfil),
    corPrimaria: parceiro?.corPrimaria ?? '',
    corSecundaria: parceiro?.corSecundaria ?? '',
    corHeader: parceiro?.corHeader ?? '',
    corFonteHeader: parceiro?.corFonteHeader ?? '',
    corFundoLogin: parceiro?.corFundoLogin ?? '',
    urlWhitelabel: parceiro?.urlWhitelabel ?? '',
    fileLogoLogin: parceiro?.fileLogoLogin?.nomeArquivo ?? '',
    fileLogoHeader: parceiro?.fileLogoHeader?.nomeArquivo ?? '',
    fileLogoHeaderRecolhido: parceiro?.fileLogoHeaderRecolhido?.nomeArquivo ?? '',
  };

  const rules = {
    // Dados do Parceiro
    nomeParceiro: validators.string({ required: true, maxLength: 100 }),

    cpfCnpj: validators.string({ required: true }),
    emailProprietario: validators.email({ required: false }),
    emailFaturamento: validators.email({ required: false }),
    celularFaturamento: validators.phone({
      required: false
    }),
    celularProprietario: validators.phone({
      required: false
    }),

    tipoOrigem: validators.string({ required: true, maxLength: 2 }),
    origem: validators.object({ required: true }),
    regional: validators.object({ required: true }),
    nomeProprietario: validators.string({ required: false, maxLength: 100 }),
    nomeFaturamento: validators.string({ required: false, maxLength: 100 }),
    cep: validators.string({ required: false, maxLength: 8 }),
    logradouro: validators.string({ required: false, maxLength: 40 }),
    numeroLogradouro: validators.string({ required: false, maxLength: 7 }),
    complementoLogradouro: validators.string({
      required: false,
      maxLength: 30
    }),
    bairro: validators.string({ required: false, maxLength: 40 }),
    municipio: validators.string({ required: false, maxLength: 60 }),
    uf: validators.string({ required: false, maxLength: 2 }),
    idUsuarioCriacao: validators.number({ required: true, min: 1 }),
    dataHoraCriacao: validators.date({ required: true }),
    idUsuarioAlteracao: validators.number({ required: true, min: 1 }),
    dataHoraAlteracao: validators.date({ required: true }),
    idRegional: validators.number({ required: true, min: 1 }),
    tipoSituacao: validators.string({ required: true, maxLength: 1 })
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues: {
      ...initialValues
    },
  };

  useEffect(() => {
    if (!!parceiro) {
      formProps.reset({
        ...parceiro,
        origem: origensParceiro.find(
          (el) => el.codigo === parceiro?.tipoOrigem
        ),
        perfil: perfis.find((el) => el.codigo === parceiro?.perfil),
        regional:
          regionais?.find((el) => el.idRegional === parceiro?.idRegional) ??
          null,
        flagSimulacao: parceiro.flagSimulacao === 'S' ? true : false,
        uf: ufs.find((el) => el.codigo === parceiro.uf) ?? null,
        cpfCnpj: parceiro?.cpfCnpj ?? null,
        fileLogoLogin: parceiro?.fileLogoLogin?.nomeArquivo ?? '',
        fileLogoHeader: parceiro?.fileLogoHeader?.nomeArquivo ?? '',
        fileLogoHeaderRecolhido: parceiro?.fileLogoHeaderRecolhido ?? '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parceiro, regionais]);

  useEffect(() => {
    if (!!parceiro && !!arquivos) {
      formProps.reset({
        ...parceiro,
        origem: origensParceiro.find(
          (el) => el.codigo === parceiro?.tipoOrigem
        ),
        perfil: perfis.find((el) => el.codigo === parceiro?.perfil),
        regional:
          regionais?.find((el) => el.idRegional === parceiro?.idRegional) ??
          null,
        flagSimulacao: parceiro.flagSimulacao === 'S' ? true : false,
        uf: ufs.find((el) => el.codigo === parceiro.uf) ?? null,
        cpfCnpj: parceiro?.cpfCnpj ?? null,
        fileLogoLogin: arquivos.find((f) => f.tipoReferencia === "LL")?.nomeArquivo ?? '',
        fileLogoHeader: arquivos.find((f) => f.tipoReferencia === "LH")?.nomeArquivo ?? '',
        fileLogoHeaderRecolhido: arquivos.find((f) => f.tipoReferencia === "LR")?.nomeArquivo ?? '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arquivos, parceiro]);

  const handleSubmit = (values) => {
    const formattedCpfCnpj = removeNonDigitsFromString(values.cpfCnpj);

    const cpfCnpjError = validateCpfCnpj(formattedCpfCnpj);

    if (cpfCnpjError !== true) {
      formProps.setError('cpfCnpj', {
        type: 'manual',
        message: cpfCnpjError
      });
      return;
    }

    const payload = {
      ...values,
      dataAlteracao: new Date().toISOString(),
      dataCriacao: new Date().toISOString(),
      cpfCnpj: formattedCpfCnpj,
      cep: removeNonDigitsFromString(values.cep),
      idRegional: values.regional?.idRegional,
      tipoOrigem: values.origem?.codigo,
      perfil: values.perfil?.codigo,
      tipoSituacao: 'A'
    };

    if (typeof payload.uf === 'object') {
      payload.uf = payload?.uf?.codigo;
    }

    if (isEditMode) {
      dispatch(actions.editarParceiroRequest({ value: payload, files: files }));
    } else {
      dispatch(actions.criarParceiroRequest({ value: payload, files: files }));
    }
  };

  const handleGoBack = () => {
    dispatch(routeActions.redirectTo(routes.PARCEIROS));
  };

  const { setValue } = formProps;

  const successCallback = useCallback(
    ({ data }) => {
      setValue('logradouro', data?.logradouro || '', {
        shouldValidate: true
      });
      setValue('bairro', data?.bairro || '', { shouldValidate: true });

      setValue('uf', ufs.find((uf) => uf.codigo === data?.uf) ?? null, {
        shouldValidate: true
      });
      setValue('municipio', data?.localidade || '', {
        shouldValidate: true
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue]
  );

  const handleClickOpen = (nomeArquivo) => {
    setOpen({
      isOpen: true,
      file: nomeArquivo === "fileLogoLogin" ? parceiro?.fileLogoLogin : nomeArquivo === "fileLogoHeaderRecolhido" ? parceiro?.fileLogoHeaderRecolhido : parceiro?.fileLogoHeader,
      nome: nomeArquivo
    });
  };

  const handleClose = () => {
    setOpen({
      isOpen: false,
      file: "",
      nome: ""
    });
  };

  const handleDeleteClick = () => {
    dispatch(actions.deletarArquivoRequest(open?.file?.idArquivo));
    formProps.setValue(open.nome, "");
    setOpen({
      isOpen: false,
      file: "",
      nome: ""
    });
  };

  const handleZipCodeBlurred = (value) => {
    if (removeNonDigitsFromString(value)?.length === 8) {
      dispatch(
        actions.buscarCepRequest({
          value,
          successCallback
        })
      );
    }
  };

  if (loading) {
    return (
      <MainLayout>
        <Loader />
      </MainLayout>
    );
  }

  if (isEditMode && !parceiro?.idParceiro) {
    return (
      <MainLayout>
        <>
          <MainCard border={false} title={'Parceiro não encontrada'}>
            <Grid
              container
              style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
              spacing={2}
            >
              <Grid item xs={12} md={4}>
                <AnimateButton>
                  <Button
                    disableElevation
                    fullWidth
                    size="large"
                    type="button"
                    variant="outlined"
                    color="secondary"
                    onClick={handleGoBack}
                  >
                    VOLTAR
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </MainCard>
        </>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <form noValidate onSubmit={formProps.handleSubmit(handleSubmit)}>
        <MainCard
          contentSX={{
            paddingLeft: 1,
            paddingRight: 1
          }}
          border={false}
          title={
            !isEditMode ? (
              'Novo Parceiro'
            ) : (
              <Box>
                <Typography variant="h2">{parceiro?.nomeParceiro}</Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  Parceiro
                </Typography>
              </Box>
            )
          }
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              gap: 2
            }}
          >
            <Box sx={{ flex: 1 }}>
              <FormCard title="DADOS DO PARCEIRO" style={{ flex: 1 }}>
                <DadosDoParceiro
                  disabled={disabled}
                  regionais={regionais}
                  origensParceiro={origensParceiro}
                  formProps={formProps}
                  perfis={perfis}
                />
              </FormCard>
            </Box>
            <Box sx={{ flex: 1 }}>
              <FormCard title="DADOS DO PROPRIETÁRIO" style={{ flex: 1 }}>
                <DadosDoProprietario
                  disabled={disabled}
                  formProps={formProps}
                />
              </FormCard>
            </Box>
            <Box sx={{ flex: 1 }}>
              <FormCard title="DADOS DO FATURAMENTO" style={{ flex: 1 }}>
                <DadosDoFaturamento formProps={formProps} disabled={disabled} />
              </FormCard>
            </Box>
            <Box sx={{ flex: 1 }}>
              <FormCard title="ENDEREÇO" style={{ flex: 1 }}>
                <Endereco
                  formProps={formProps}
                  handleZipCodeBlurred={handleZipCodeBlurred}
                  disabled={disabled}
                />
              </FormCard>
            </Box>
            <Box sx={{ flex: 1 }}>
              <FormCard title="WHITELABEL" style={{ flex: 1 }}>
                <DadosWhitelabel
                  formProps={formProps}
                  disabled={disabled}
                  setFile={setFile}
                  handleClickOpen={handleClickOpen}
                  parceiro={parceiro}
                  arquivos={arquivos}
                />
              </FormCard>
            </Box>
            <Box sx={{ flex: 1 }}>
              <FormCard title="PALETA DE CORES" style={{ flex: 1 }}>
                <DadosPalletaCores
                  formProps={formProps}
                  disabled={disabled}
                />
              </FormCard>
            </Box>
          </Box>
        </MainCard>

        <Grid
          container
          style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
          spacing={2}
        >
          <Grid item xs={12} md={4}>
            <AnimateButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="button"
                variant="outlined"
                color="secondary"
                onClick={handleGoBack}
              >
                VOLTAR
              </Button>
            </AnimateButton>
          </Grid>
          <Grid item xs={12} md={4}>
            <AnimateButton>
              <Button
                disabled={disabled}
                disableElevation
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                type="submit"
              >
                SALVAR
              </Button>
            </AnimateButton>
          </Grid>
        </Grid>
        <Dialog open={open.isOpen} onClose={handleClose}>
          <DialogTitle>Confirmação para Exclusão de Arquivo</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Tem certeza de que deseja excluir o arquivo{' '}
              {open?.file?.nomeArquivo}? Ao excluir um arquivo, não será
              mais possível recuperar suas informações.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancelar
            </Button>
            <Button onClick={handleDeleteClick} color="error">
              Excluir
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </MainLayout>
  );
};

export default Parceiro;
