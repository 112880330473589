import React from 'react';
import { Stack } from '@mui/material';
import InputLabel from 'components/form/InputLabel';

import { Grid } from '@mui/material';
import ColorPickerInput from '../../../components/form/ColorPickerInput';

const DadosDoParceiro = ({
  formProps,
  disabled
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Stack spacing={0}>
          <InputLabel htmlFor="nomeParceiro">Cor Primária</InputLabel>
          <ColorPickerInput
            disabled={disabled}
            id="corPrimaria"
            name="corPrimaria"
            placeholder="Cor Primária"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 7 }}
          />
        </Stack>
      </Grid>

      <Grid item xs={12} md={6}>
        <Stack spacing={0}>
          <InputLabel htmlFor="corSecundaria">Cor Secundária</InputLabel>
          <ColorPickerInput
            disabled={disabled}
            id="corSecundaria"
            name="corSecundaria"
            placeholder="Cor Secundária"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 7 }}
          />
        </Stack>
      </Grid>

      <Grid item xs={12} md={6}>
        <Stack spacing={0}>
          <InputLabel htmlFor="corHeader">Cor Header</InputLabel>
          <ColorPickerInput
            disabled={disabled}
            id="corHeader"
            name="corHeader"
            placeholder="Cor Header"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 7 }}
          />
        </Stack>
      </Grid>

      <Grid item xs={12} md={6}>
        <Stack spacing={0}>
          <InputLabel htmlFor="corFonteHeader">Cor Fonte Header</InputLabel>
          <ColorPickerInput
            disabled={disabled}
            id="corFonteHeader"
            name="corFonteHeader"
            placeholder="Cor Fonte Header"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 7 }}
          />
        </Stack>
      </Grid>

      <Grid item xs={12} md={6}>
        <Stack spacing={0}>
          <InputLabel htmlFor="corFundoLogin">Cor Fundo Login</InputLabel>
          <ColorPickerInput
            disabled={disabled}
            id="corFundoLogin"
            name="corFundoLogin"
            placeholder="Cor Fundo Login"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 7 }}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DadosDoParceiro;
