import { createSelector } from 'reselect';

export const getUsuario = (state) => state.usuario;

export const isUsuarioLogado = createSelector(
  getUsuario,
  (state) => !!state?.nome
);

export const solicitouToken = createSelector(
  getUsuario,
    (state) => !!state?.dataLimiteToken
);

export const getPerfil = createSelector(getUsuario, (state) => state.perfil);

export const getParceiro = createSelector(
  getUsuario,
  (state) => !!state?.idParceiro
);