import logo from '../../assets/images/homefin-icone-branco.png';
import { useSelector } from 'react-redux';

// ==============================|| LOGO SVG ||============================== //

const LogoSmall = () => {
  const logos = useSelector((state) => state.whitelabel.parceiro.logo);
  const logoHeader = logos && logos.length > 0 ? logos?.find((f) => f.tipoReferencia === "LR")?.conteudo : "";

  return <img src={logoHeader ? `data:image/png;base64,${logoHeader}` : logo} alt="Homefin" width="37" height="35" />;
};

export default LogoSmall;
