import React, { useState, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import { TextField, FormControl, FormHelperText, Box } from '@mui/material';
import { Controller } from 'react-hook-form';

const ColorPickerInput = ({
                            formProps,
                            name,
                            ignoreError = false,
                            loading,
                            ...otherProps
                          }) => {
  const {
    control,
    formState: { errors },
    rules,
  } = formProps;

  const initialValue = formProps?.initialValues?.[name] || '#000'; // Valor inicial do campo
  const [color, setColor] = useState(initialValue); // Estado local inicializado com o valor do formulário.

  const isError = errors[name] !== undefined && !ignoreError;

  // Sincroniza o estado local com o valor inicial do formulário.
  useEffect(() => {
    if (initialValue) {
      setColor(initialValue);
    }
  }, [initialValue]);

  const selecionarCor = (cor) => {
    setColor(cor);
    formProps.setValue(name, cor);
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules[name]}
      render={({ field: { onChange, onBlur, value = initialValue } }) => (
        <FormControl error={isError} fullWidth>
          {/* Campo de texto */}
          <TextField
            {...otherProps}
            value={value || color} // Exibe o valor atual do formulário ou o estado local.
            error={isError}
            disabled
            size="small"
            sx={{
              '& .MuiOutlinedInput-input': {
                minHeight: '22.13px',
              },
            }}
            onChange={(e) => {
              onChange(e);
              if (otherProps.onChange) {
                otherProps.onChange(e);
              }
            }}
            onBlur={() => {
              onBlur();
              if (otherProps.onBlur) {
                otherProps.onBlur();
              }
            }}
          />

          {/* Espaçamento entre o campo texto e o picker */}
          <Box sx={{ marginTop: 2 }}>
            {/* Componente SketchPicker */}
            <SketchPicker
              color={color}
              onChangeComplete={(selectedColor) => {
                selecionarCor(selectedColor.hex); // Atualiza o estado local.
              }}
            />
          </Box>

          {/* Mensagem de erro */}
          {!ignoreError && errors[name] && (
            <FormHelperText>{errors[name]?.message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default ColorPickerInput;
