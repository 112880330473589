import logo from '../../assets/images/homefin-horizontal-fundo-gradiente.png';
import { useSelector } from 'react-redux';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  const logos = useSelector((state) => state.whitelabel.parceiro.logo);
  const logoHeader = logos && logos.length > 0 ? logos?.find((f) => f.tipoReferencia === "LH")?.conteudo : "";
  return <img src={logoHeader ? `data:image/png;base64,${logoHeader}` : logo} alt="Homefin" width="140" height="32.344" />;

};

export default Logo;
