import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  parceiro: {
    loading: false,
    error: false,
    paleta: {}
  },
};

const WhitelabelSlice = createSlice({
  name: 'whitelabel',
  initialState,
  reducers: {
    obterDadosRequest: (state) => {
      state.parceiro.loading = true;
      state.parceiro.error = null;
      state.parceiro.data = [];
    },
    obterDadosSuccess: (state, action) => {
      state.parceiro.loading = false;
      state.parceiro.error = false;
      state.parceiro.paleta = action.payload.response.data.paleta ? action.payload.response.data.paleta : "";
      state.parceiro.logo = action.payload.response.data.logo ? action.payload.response.data.logo : "";
    },
    obterDadosFailure: (state, action) => {
      state.parceiro.loading = false;
      state.parceiro.error = action.payload;
    },
  }
});

export const {
  obterDadosRequest,
  obterDadosSuccess,
  obterDadosFailure,
} = WhitelabelSlice.actions;

export default WhitelabelSlice.reducer;
