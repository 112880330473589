/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */
import logo from '../../assets/images/homefin-horizontal-branco-transparente-768x177.png';
import { useSelector } from 'react-redux';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  const logos = useSelector((state) => state.whitelabel.parceiro.logo);
  const logoLogin = logos && logos.length > 0 ? logos?.find((f) => f.tipoReferencia === "LL")?.conteudo : "";

  return <img src={logoLogin ? `data:image/png;base64,${logoLogin}` : logo} alt="Homefin" width="220" style={{ maxHeight: '70px', objectFit: 'contain' }}/>;
};

export default Logo;
