import React, { useRef } from 'react';
import { Button, Stack, Grid } from '@mui/material';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import OutlinedInput from 'components/form/OutlinedInput';
import InputLabel from 'components/form/InputLabel';

const DadosDoParceiro = ({ formProps, disabled, setFile, handleClickOpen, arquivos }) => {
  const fileInputRefLogin = useRef(null);
  const fileInputRefHeader = useRef(null);
  const fileInputRefHeaderRecolhido = useRef(null);

  const handleButtonClick = (inputType) => {
    if (inputType === 'fileLogoLogin') {
      fileInputRefLogin.current.click();
    } else if (inputType === 'fileLogoHeader') {
      fileInputRefHeader.current.click();
    } else {
      fileInputRefHeaderRecolhido.current.click();
    }
  };

  const handleFileChange = (event, inputType) => {
    const file = event.target.files[0];
    if (file) {
      const validExtensions = ['pdf', 'png', 'jpeg', 'jpg'];
      const maxSize = 10 * 1024 * 1024;
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (!validExtensions.includes(fileExtension)) {
        toast.error('Tipo de arquivo inválido');
        return;
      }

      if (file.size > maxSize) {
        toast.error('Tamanho do Arquivo não pode exceder 10MB');
        return;
      }

      // Atualiza o estado com o nome do arquivo selecionado
      setFile((prev) => ({ ...prev, [inputType]: file }));
      formProps.setValue(inputType, file.name);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Stack spacing={0}>
          <InputLabel htmlFor="nomeParceiro">URL</InputLabel>
          <OutlinedInput
            disabled={disabled}
            id="urlWhitelabel"
            name="urlWhitelabel"
            placeholder="URL"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 50 }}
          />
        </Stack>
      </Grid>
      <Grid item xs={6} md={6}>
        <Stack spacing={0}>
          <InputLabel htmlFor="fileLogoLogin">Logo Tela Login</InputLabel>
          <OutlinedInput
            disabled={true}
            id="fileLogoLogin"
            name="fileLogoLogin"
            fullWidth
            formProps={formProps}
            inputProps={{ readOnly: true }}
          />
          <input
            type="file"
            ref={fileInputRefLogin}
            style={{ display: 'none' }}
            onChange={(e) => handleFileChange(e, 'fileLogoLogin')}
            accept=".pdf,.png,.jpeg,.jpg"
          />
        </Stack>
      </Grid>
      <Grid item xs={6} md={2} sx={{ paddingTop: '40px !important', display: arquivos.find((f) => f.tipoReferencia === "LL") ? 'none' : 'block'}}>
        <Button
          variant="text"
          color="primary"
          onClick={() => handleButtonClick('fileLogoLogin')}
          startIcon={<PlusCircleOutlined />}
        >
          Adicionar Arquivo
        </Button>
      </Grid>
      <Grid item xs={6} md={2} sx={{ paddingTop: '40px !important', display: arquivos.find((f) => f.tipoReferencia === "LL") ? 'block' : 'none'}}>
        <Button
          variant="text"
          color="primary"
          onClick={() => handleClickOpen("fileLogoLogin")}
          startIcon={<DeleteOutlined />}
        >
          Excluir
        </Button>
      </Grid>
      <Grid item xs={6} md={6}>
        <Stack spacing={0}>
          <InputLabel htmlFor="fileLogoHeader">Logo Header</InputLabel>
          <OutlinedInput
            disabled={true}
            id="fileLogoHeader"
            name="fileLogoHeader"
            fullWidth
            formProps={formProps}
            inputProps={{ readOnly: true }}
          />
          <input
            type="file"
            ref={fileInputRefHeader}
            style={{ display: 'none' }}
            onChange={(e) => handleFileChange(e, 'fileLogoHeader')}
            accept=".pdf,.png,.jpeg,.jpg"
          />
        </Stack>
      </Grid>
      <Grid item xs={6} md={2} sx={{ paddingTop: '40px !important', display: arquivos.find((f) => f.tipoReferencia === "LH") ? 'none' : 'block'}}>
        <Button
          variant="text"
          color="primary"
          onClick={() => handleButtonClick('fileLogoHeader')}
          startIcon={<PlusCircleOutlined />}
        >
          Adicionar Arquivo
        </Button>
      </Grid>
      <Grid item xs={6} md={2} sx={{ paddingTop: '40px !important', display: arquivos.find((f) => f.tipoReferencia === "LH") ? 'block' : 'none'}}>
        <Button
          variant="text"
          color="primary"
          onClick={() => handleClickOpen("fileLogoHeader")}
          startIcon={<DeleteOutlined />}
        >
          Excluir
        </Button>
      </Grid>
      <Grid item xs={6} md={6}>
        <Stack spacing={0}>
          <InputLabel htmlFor="fileLogoHeaderRecolhido">Logo Header - Minimizado</InputLabel>
          <OutlinedInput
            disabled={true}
            id="fileLogoHeaderRecolhido"
            name="fileLogoHeaderRecolhido"
            fullWidth
            formProps={formProps}
            inputProps={{ readOnly: true }}
          />
          <input
            type="file"
            ref={fileInputRefHeaderRecolhido}
            style={{ display: 'none' }}
            onChange={(e) => handleFileChange(e, 'fileLogoHeaderRecolhido')}
            accept=".pdf,.png,.jpeg,.jpg"
          />
        </Stack>
      </Grid>
      <Grid item xs={6} md={2} sx={{ paddingTop: '40px !important', display: arquivos.find((f) => f.tipoReferencia === "LR") ? 'none' : 'block'}}>
        <Button
          variant="text"
          color="primary"
          onClick={() => handleButtonClick('fileLogoHeaderRecolhido')}
          startIcon={<PlusCircleOutlined />}
        >
          Adicionar Arquivo
        </Button>
      </Grid>
      <Grid item xs={6} md={2} sx={{ paddingTop: '40px !important', display: arquivos.find((f) => f.tipoReferencia === "LR") ? 'block' : 'none'}}>
        <Button
          variant="text"
          color="primary"
          onClick={() => handleClickOpen("fileLogoHeaderRecolhido")}
          startIcon={<DeleteOutlined />}
        >
          Excluir
        </Button>
      </Grid>
    </Grid>
  );
};

export default DadosDoParceiro;


