// third-party
import { combineSlices } from '@reduxjs/toolkit';

// project import
import menu from './menu';
import { reducer as routesReducer } from '../routes.js';
import { default as auth } from './autenticacao.reducer';
import { default as user } from './user.reducer';
import { default as dominios } from './dominios.reducer';
import { default as simulacao } from './simulacao.reducer';
import { default as oportunidade } from './oportunidade.reducer';
import { default as compradores } from './compradores.reducer';
import { default as vendedores } from './vendedores.reducer';
import { default as atividades } from './atividades.reducer';
import { default as fup } from './fup.reducer';
import { default as documentos } from './documentos.reducer';
import { default as minhasAtividades } from './minhasAtividades.reducer';
import { default as pesquisa } from './pesquisa.reducer';
import { default as bancos } from './bancos.reducer';
import { default as regionais } from './regionais.reducer';
import { default as configuracaoDocumentos } from './configuracaoDocumentos.reducer';
import { default as produtos } from './produtosOperacoes.reducer';
import { default as produtosEtapas } from './produtosEtapas.reducer';
import { default as produtosAtividades } from './produtosAtividades.reducer';
import { default as produtosAtividadesBanco } from './produtosAtividadesBanco.reducer';
import { default as parceiros } from './parceiros.reducer';
import { default as usuariosParceiros } from './usuariosParceiros.reducer';
import { default as usuarios } from './usuarios.reducer';
import { default as whitelabel } from './whitelabel.reducer';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineSlices({
  menu,
  location: routesReducer,
  auth,
  usuario: user,
  dominios,
  simulacao,
  oportunidade,
  compradores,
  vendedores,
  atividades,
  fup,
  documentos,
  minhasAtividades,
  pesquisa,
  bancos,
  regionais,
  configuracaoDocumentos,
  produtos,
  produtosEtapas,
  produtosAtividades,
  produtosAtividadesBanco,
  parceiros,
  usuariosParceiros,
  usuarios,
  whitelabel,
});

export default reducers;
