import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Box, Grid, useTheme } from '@mui/material';

// project import
import AuthCard from './AuthCard';
import Logo from '../../components/Logo';

// ==============================|| AUTHENTICATION - WRAPPER ||============================== //

const AuthWrapper = ({ children }) => {
  const theme = useTheme();

  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        sx= {theme.palette.background.auth === 'transparent' ?
          {
            minHeight: '100vh',
            backgroundImage: theme.palette.background.gradient,
          } :
          {
            minHeight: '100vh',
            backgroundColor: theme.palette.background.auth,
          }
        }
      >
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 10 }}
        >
          <Logo />
        </Grid>
        <Grid item xs={12}>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: { xs: 'calc(100vh - 134px)', md: 'calc(100vh - 112px)' } }}
          >
            <Grid item>
              <AuthCard>{children}</AuthCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

AuthWrapper.propTypes = {
  children: PropTypes.node
};

export default AuthWrapper;
